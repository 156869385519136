<template lang="pug">
  b-row.mb-5
    b-col

      b-card.manager-accounts-list.card-white.card-no-gutters.shadow(no-body)
        b-card-header.bg-transparent.border-0
          wc-tables-header(
            :title="$t('activerecord.models.account.other')"
            @click-data-clean-fetch='dataCleanFetch'
            @click-data-fetch='dataFetch')

        b-card-body
          b-row
            b-col
              wc-tables-filter(
                :filter.sync='filter'
                :p-page.sync='pagination.pPage'
                :p-page-options='pagination.pPageOptions'
                @submit='dataFetch'
                @submit-clear='filterClear')

          b-row
            b-col
              transition(name='fade')
                wc-tables-wrapper-default(
                  :ref='wns'
                  :items='items'
                  :fields='fields'
                  :sort-by.sync='sorting.sortBy'
                  :sort-desc.sync='sorting.sortDesc'
                  :is-loading='isLoading'
                  :action-delete='actionDestroy'
                  @row-clicked='rowClicked'
                  @row-delete='rowDelete'
                  @sort-changed='sortingChanged'
                  attr-active)
                  template(v-slot:cell(attributes.identity_username)='data')
                    | {{ $getDeep(included.find(x => x.type === 'identity' && x.id === $getDeep(data.item, 'relationships.identity.data.id')), 'attributes.username') }}

        b-card-footer.border-0.bg-white
          wc-tables-footer(
            :total-items='pagination.totalItems'
            :p-page='pagination.pPage'
            :c-page.sync='pagination.cPage')

        loading(
          :active.sync='isLoading'
          :can-cancel-esc='true'
          :on-cancel='onCancel')
</template>

<script>
import Table from '@common/table'
import apiAccounts from '@services/api/manager/accounts'

export default {
  name: 'manager-accounts-list',
  mixins: [Table],
  props: {
    wns: {
      type: String,
      default: 'acc',
    },
  },
  methods: {
    routeParams(item) {
      return {
        edit: [{ name: 'manager-accounts-edit', params: { account_id: item.attributes.sid } }],
      }
    },
  },
  data() {
    return {
      apiBase: apiAccounts,
      actionRowClicked: 'edit',
      fields: [
        {
          key: 'id',
          label: this.$t('activerecord.attributes.shared.id'),
          sortable: true,
          attr: 'accounts.id',
          class: 'dots-100 h-100',
          noClick: true,
        },
        {
          key: 'attributes.eid',
          label: this.$t('activerecord.attributes.shared.eid'),
          sortable: true,
          attr: 'accounts.eid',
        },
        {
          key: 'attributes.email',
          label: this.$t('activerecord.attributes.account.email'),
          sortable: true,
          attr: 'accounts.email',
        },
        {
          key: 'attributes.identity_username',
          label: this.$t('activerecord.models.identity.one'),
          sortable: true,
          attr: 'identities.username',
        },
        {
          key: 'attributes.discarded_at',
          label: this.$t('activerecord.attributes.shared.discarded_at'),
          sortable: true,
          attr: 'accounts.discarded_at',
        },
        {
          key: 'attributes.updated_at',
          label: this.$t('activerecord.attributes.shared.updated_at'),
          sortable: true,
          attr: 'accounts.updated_at',
        },
        {
          key: 'attributes.created_at',
          label: this.$t('activerecord.attributes.shared.created_at'),
          sortable: true,
          attr: 'accounts.created_at',
        },
      ],
    }
  },
}
</script>
